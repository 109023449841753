export enum ApplicationTheme {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export interface UserPreferences {
  sendEmailOnSimulationsSuccess?: boolean;
  feedbackDateScheduledInDays?: number;
  applicationTheme?: ApplicationTheme;
}

export interface UserPreferencesUpdateResponseSuccess {
  message?: string;
}

export interface UserPreferencesUpdateRequest {
  requestCode: UserPreferenceKey;
  value?: unknown;
  dayModifier?: number;
}

export enum UserPreferenceKey {
  sendEmailOnSimulationsSuccess = 'sendEmailOnSimulationsSuccess',
  feedbackDateScheduledInDays = 'updateFeedbackDate',
  applicationTheme = 'applicationTheme',
}

export enum ApplicationSettingsKey {
  theme = 'prosumer-theme',
}
